import React, { useState, useEffect, useRef, useCallback } from 'react';
import { X } from 'lucide-react';
import './HeroPicker.css';

// Mock data for Dota 2 heroes with attributes
const allHeroes = [
  { name: "Alchemist", attribute: "strength" },
  { name: "Ancient Apparition", attribute: "intelligence" },
  { name: "Anti-Mage", attribute: "agility" },
  { name: "Arc Warden", attribute: "agility" },
  { name: "Axe", attribute: "strength" },
  { name: "Bane", attribute: "universal" },
  { name: "Batrider", attribute: "universal" },
  { name: "Beastmaster", attribute: "universal" },
  { name: "Bloodseeker", attribute: "agility" },
  { name: "Bounty Hunter", attribute: "agility" },
  { name: "Brewmaster", attribute: "universal" },
  { name: "Bristleback", attribute: "strength" },
  { name: "Broodmother", attribute: "universal" },
  { name: "Centaur Warrunner", attribute: "strength" },
  { name: "Chaos Knight", attribute: "strength" },
  { name: "Chen", attribute: "universal" },
  { name: "Clinkz", attribute: "agility" },
  { name: "Clockwerk", attribute: "universal" },
  { name: "Crystal Maiden", attribute: "intelligence" },
  { name: "Dark Seer", attribute: "universal" },
  { name: "Dark Willow", attribute: "universal" },
  { name: "Dawnbreaker", attribute: "strength" },
  { name: "Death Prophet", attribute: "intelligence" },
  { name: "Disruptor", attribute: "intelligence" },
  { name: "Doom", attribute: "strength" },
  { name: "Dragon Knight", attribute: "strength" },
  { name: "Drow Ranger", attribute: "agility" },
  { name: "Earth Spirit", attribute: "strength" },
  { name: "Earthshaker", attribute: "strength" },
  { name: "Elder Titan", attribute: "strength" },
  { name: "Ember Spirit", attribute: "agility" },
  { name: "Enchantress", attribute: "intelligence" },
  { name: "Enigma", attribute: "universal" },
  { name: "Faceless Void", attribute: "agility" },
  { name: "Grimstroke", attribute: "intelligence" },
  { name: "Gyrocopter", attribute: "agility" },
  { name: "Hoodwink", attribute: "agility" },
  { name: "Huskar", attribute: "strength" },
  { name: "Invoker", attribute: "universal" },
  { name: "Io", attribute: "universal" },
  { name: "Jakiro", attribute: "intelligence" },
  { name: "Juggernaut", attribute: "agility" },
  { name: "Keeper of the Light", attribute: "intelligence" },
  { name: "Kez", attribute: "agility" },
  { name: "Kunkka", attribute: "strength" },
  { name: "Legion Commander", attribute: "strength" },
  { name: "Leshrac", attribute: "intelligence" },
  { name: "Lich", attribute: "intelligence" },
  { name: "Lifestealer", attribute: "strength" },
  { name: "Lina", attribute: "intelligence" },
  { name: "Lion", attribute: "intelligence" },
  { name: "Lone Druid", attribute: "universal" },
  { name: "Luna", attribute: "agility" },
  { name: "Lycan", attribute: "universal" },
  { name: "Magnus", attribute: "universal" },
  { name: "Marci", attribute: "universal" },
  { name: "Mars", attribute: "strength" },
  { name: "Medusa", attribute: "agility" },
  { name: "Meepo", attribute: "agility" },
  { name: "Mirana", attribute: "universal" },
  { name: "Monkey King", attribute: "agility" },
  { name: "Morphling", attribute: "agility" },
  { name: "Muerta", attribute: "intelligence" },
  { name: "Naga Siren", attribute: "agility" },
  { name: "Nature's Prophet", attribute: "intelligence" },
  { name: "Necrophos", attribute: "intelligence" },
  { name: "Night Stalker", attribute: "strength" },
  { name: "Nyx Assassin", attribute: "universal" },
  { name: "Ogre Magi", attribute: "strength" },
  { name: "Omniknight", attribute: "strength" },
  { name: "Oracle", attribute: "intelligence" },
  { name: "Outworld Destroyer", attribute: "intelligence" },
  { name: "Pangolier", attribute: "universal" },
  { name: "Phantom Assassin", attribute: "agility" },
  { name: "Phantom Lancer", attribute: "agility" },
  { name: "Phoenix", attribute: "universal" },
  { name: "Primal Beast", attribute: "strength" },
  { name: "Puck", attribute: "intelligence" },
  { name: "Pudge", attribute: "strength" },
  { name: "Pugna", attribute: "intelligence" },
  { name: "Queen of Pain", attribute: "intelligence" },
  { name: "Razor", attribute: "agility" },
  { name: "Riki", attribute: "agility" },
  { name: "Ringmaster", attribute: "intelligence" },
  { name: "Rubick", attribute: "intelligence" },
  { name: "Sand King", attribute: "universal" },
  { name: "Shadow Demon", attribute: "intelligence" },
  { name: "Shadow Fiend", attribute: "universal" },
  { name: "Shadow Shaman", attribute: "intelligence" },
  { name: "Silencer", attribute: "intelligence" },
  { name: "Skywrath Mage", attribute: "intelligence" },
  { name: "Slardar", attribute: "strength" },
  { name: "Slark", attribute: "agility" },
  { name: "Sniper", attribute: "agility" },
  { name: "Spectre", attribute: "agility" },
  { name: "Spirit Breaker", attribute: "strength" },
  { name: "Storm Spirit", attribute: "intelligence" },
  { name: "Sven", attribute: "strength" },
  { name: "Techies", attribute: "universal" },
  { name: "Templar Assassin", attribute: "agility" },
  { name: "Terrorblade", attribute: "agility" },
  { name: "Tidehunter", attribute: "strength" },
  { name: "Timbersaw", attribute: "universal" },
  { name: "Tinker", attribute: "intelligence" },
  { name: "Tiny", attribute: "strength" },
  { name: "Treant Protector", attribute: "strength" },
  { name: "Troll Warlord", attribute: "agility" },
  { name: "Tusk", attribute: "strength" },
  { name: "Underlord", attribute: "strength" },
  { name: "Undying", attribute: "strength" },
  { name: "Ursa", attribute: "agility" },
  { name: "Vengeful Spirit", attribute: "universal" },
  { name: "Venomancer", attribute: "universal" },
  { name: "Viper", attribute: "agility" },
  { name: "Visage", attribute: "universal" },
  { name: "Void Spirit", attribute: "universal" },
  { name: "Warlock", attribute: "intelligence" },
  { name: "Weaver", attribute: "agility" },
  { name: "Windranger", attribute: "universal" },
  { name: "Winter Wyvern", attribute: "universal" },
  { name: "Witch Doctor", attribute: "intelligence" },
  { name: "Wraith King", attribute: "strength" },
  { name: "Zeus", attribute: "intelligence" }
];


const HeroPicker = () => {
  const [enemyHeroes, setEnemyHeroes] = useState([]);
  const [heroPool, setHeroPool] = useState([]);
  const [teamMateHeroes, setTeamMateHeroes] = useState([]);
  const [enemyInputValue, setEnemyInputValue] = useState('');
  const [poolInputValue, setPoolInputValue] = useState('');
  const [teamMateInputValue, setTeamMateInputValue] = useState('');
  const [enemySuggestions, setEnemySuggestions] = useState([]);
  const [poolSuggestions, setPoolSuggestions] = useState([]);
  const [teamMateSuggestions, setTeamMateSuggestions] = useState([]);
  const [counterPicks, setCounterPicks] = useState(null);
  const [doubleDownResults, setDoubleDownResults] = useState(null);
  const [showCounterPicks, setShowCounterPicks] = useState(false);
  const [showDoubleDown, setShowDoubleDown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [tableEnemyHeroes, setTableEnemyHeroes] = useState([]);
  const [tableHeroPool, setTableHeroPool] = useState([]);
  const [isLowIQMode, setIsLowIQMode] = useState(false);
  const [isLoading, setIsLoading] = useState({
    counterPicks: false,
    doubleDown: false
  });
  const [placeholderText, setPlaceholderText] = useState({
    pool: "Type hero name to add to your pool...",
    enemy: "Type hero name...",
    teamMate: "Type hero name..."
  });

  const enemyInputRef = useRef(null);
  const poolInputRef = useRef(null);
  const teamMateInputRef = useRef(null);
  const placeholderIntervalRef = useRef(null);

  // New state and ref for image preloading
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const preloadedImages = useRef({});

  const toggleLowIQMode = () => {
    setIsLowIQMode(!isLowIQMode);
    if (!isLowIQMode && !heroPool.includes("Pudge")) {
      setHeroPool(prevPool => [...prevPool, "Pudge"]);
    }
  };

  // Image preloading function
  const preloadImages = useCallback(() => {
    const imagePromises = allHeroes.map(hero => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const heroName = hero.name.toLowerCase().replaceAll(/ /g, '_').replaceAll('-', '_').replaceAll('\'', '');
        img.src = `/hero-icons/${heroName}.png`;
        img.onload = () => {
          preloadedImages.current[heroName] = img;
          resolve();
        };
        img.onerror = reject;
      });
    });

    Promise.all(imagePromises)
      .then(() => setImagesLoaded(true))
      .catch(error => console.error('Error preloading images:', error));
  }, []);

  useEffect(() => {
    preloadImages();
  }, [preloadImages]);

  // Load hero pool from localStorage on component mount
  useEffect(() => {
    try {
      const cachedHeroPool = localStorage.getItem('heroPool');
      if (cachedHeroPool) {
        const parsedHeroPool = JSON.parse(cachedHeroPool);
        setHeroPool(parsedHeroPool);
      }
    } catch (error) {
      console.error('Error loading hero pool from localStorage:', error);
    }
  }, []);

  // Update localStorage whenever hero pool changes
  useEffect(() => {
    try {
      if (heroPool.length > 0) {
        localStorage.setItem('heroPool', JSON.stringify(heroPool));
      }
    } catch (error) {
      console.error('Error saving hero pool to localStorage:', error);
    }
  }, [heroPool]);

  const getHeroImagePath = useCallback((heroName) => {
    const formattedName = heroName.toLowerCase().replaceAll(/ /g, '_').replaceAll('-', '_').replaceAll('\'', '');
    return preloadedImages.current[formattedName]?.src || '';
  }, []);

  const sortHeroesByRelevance = (heroes, inputValue) => {
    return heroes.sort((a, b) => {
      const aStartsWith = a.name.toLowerCase().startsWith(inputValue.toLowerCase());
      const bStartsWith = b.name.toLowerCase().startsWith(inputValue.toLowerCase());
      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;
      return a.name.localeCompare(b.name);
    });
  };

  useEffect(() => {
    if (enemyInputValue.length > 0) {
      const filtered = allHeroes.filter(hero =>
        hero.name.toLowerCase().startsWith(enemyInputValue.toLowerCase()) &&
        !enemyHeroes.includes(hero.name) &&
        !teamMateHeroes.includes(hero.name)
      );
      const sorted = sortHeroesByRelevance(filtered, enemyInputValue);
      setEnemySuggestions(sorted);
    } else {
      setEnemySuggestions([]);
    }
  }, [enemyInputValue, enemyHeroes, teamMateHeroes]);

  useEffect(() => {
    if (poolInputValue.length > 0) {
      const filtered = allHeroes.filter(hero =>
        hero.name.toLowerCase().startsWith(poolInputValue.toLowerCase()) &&
        !heroPool.includes(hero.name)
      );
      const sorted = sortHeroesByRelevance(filtered, poolInputValue);
      setPoolSuggestions(sorted);
    } else {
      setPoolSuggestions([]);
    }
  }, [poolInputValue, heroPool]);

  useEffect(() => {
    if (teamMateInputValue.length > 0) {
      const filtered = allHeroes.filter(hero =>
        hero.name.toLowerCase().startsWith(teamMateInputValue.toLowerCase()) &&
        !teamMateHeroes.includes(hero.name) &&
        !enemyHeroes.includes(hero.name)
      );
      const sorted = sortHeroesByRelevance(filtered, teamMateInputValue);
      setTeamMateSuggestions(sorted);
    } else {
      setTeamMateSuggestions([]);
    }
  }, [teamMateInputValue, teamMateHeroes, enemyHeroes]);

  const addHero = (hero, type) => {
    if (type === 'enemy' && enemyHeroes.length < 5 && !enemyHeroes.includes(hero.name) && !teamMateHeroes.includes(hero.name)) {
      setEnemyHeroes(prevHeroes => [...prevHeroes, hero.name]);
      setEnemyInputValue('');
      enemyInputRef.current.focus();
    } else if (type === 'pool' && !heroPool.includes(hero.name)) {
      setHeroPool(prevHeroes => [...prevHeroes, hero.name]);
      setPoolInputValue('');
      poolInputRef.current.focus();
    } else if (type === 'teamMate' && teamMateHeroes.length < 5 && !teamMateHeroes.includes(hero.name) && !enemyHeroes.includes(hero.name)) {
      setTeamMateHeroes(prevHeroes => [...prevHeroes, hero.name]);
      setTeamMateInputValue('');
      teamMateInputRef.current.focus();
    }
  };

  const removeHero = (hero, type) => {
    if (type === 'enemy') {
      setEnemyHeroes(prevHeroes => prevHeroes.filter(h => h !== hero));
    } else if (type === 'pool') {
      setHeroPool(prevHeroes => prevHeroes.filter(h => h !== hero));
    } else if (type === 'teamMate') {
      setTeamMateHeroes(prevHeroes => prevHeroes.filter(h => h !== hero));
    }
  };


  const renderHeroList = (heroes, type) => {
    const handleNoHeroesClick = () => {
      switch (type) {
        case 'pool':
          poolInputRef.current.focus();
          setActiveDropdown('pool');
          break;
        case 'enemy':
          enemyInputRef.current.focus();
          setActiveDropdown('enemy');
          break;
        case 'teamMate':
          teamMateInputRef.current.focus();
          setActiveDropdown('teamMate');
          break;
      }
    };

    return (
      <div className="hero-list" onClick={heroes.length === 0 ? handleNoHeroesClick : undefined}>
        {heroes.length > 0 ? (heroes.map((hero, index) => (
          <div key={`${hero}-${index}`} className={`hero-item ${type}`}>
            <img src={getHeroImagePath(hero)} alt={hero} title={hero} className="hero-image" />
            <span className="hero-name"></span>
            <button onClick={() => removeHero(hero, type)} className="remove-button">
              <X size={16} />
            </button>
          </div>
        ))) : (
          <div className="no-heroes-label">No heroes selected</div>
        )}
      </div>
    );
  };

  const renderSuggestions = (suggestions, type) => (
    <div className="suggestions-horizontal">
      {suggestions.map(hero => (
        <div
          key={hero.name}
          className="suggestion-item"
          onClick={() => addHero(hero, type)}
        >
          <img 
            src={getHeroImagePath(hero.name)} 
            alt={hero.name} 
            className="suggestion-image"
          />
          <span className="suggestion-name">{hero.name}</span>
        </div>
      ))}
    </div>
  );

  const renderHeroIcon = useCallback((hero, onHeroPick, selectedHeroes, disabledHeroes) => {
    const isSelected = selectedHeroes.includes(hero.name);
    const isDisabled = disabledHeroes.includes(hero.name);
    return (
      <img
        key={hero.name}
        src={getHeroImagePath(hero.name)}
        alt={hero.name}
        className={`hero-icon ${isSelected || isDisabled ? 'greyed-out' : ''}`}
        onClick={() => !isSelected && !isDisabled && onHeroPick(hero)}
        title={hero.name}
      />
    );
  }, [getHeroImagePath]);

  const HeroPickView = useCallback(({ onHeroPick, selectedHeroes, disabledHeroes }) => {
    const heroAttributes = ["strength", "agility", "intelligence", "universal"];
  
    return (
      <div className="hero-pick-view">
        {heroAttributes.map(attribute => (
          <div key={attribute} className="attribute-column">
            <h3 className="attribute-title">{attribute.charAt(0).toUpperCase() + attribute.slice(1)}</h3>
            <div className="hero-grid">
              {allHeroes
                .filter(hero => hero.attribute === attribute)
                .map(hero => renderHeroIcon(hero, onHeroPick, selectedHeroes, disabledHeroes))}
            </div>
          </div>
        ))}
      </div>
    );
  }, [renderHeroIcon]);

  const renderDropdown = (type) => {
    let suggestions, inputValue, placeholder, onHeroPick, selectedHeroes, inputRef, maxHeroes, disabledHeroes;

    switch (type) {
      case 'enemy':
        suggestions = enemySuggestions;
        inputValue = enemyInputValue;
        placeholder = "Type enemy hero name...";
        onHeroPick = (hero) => addHero(hero, 'enemy');
        selectedHeroes = enemyHeroes;
        disabledHeroes = teamMateHeroes;
        inputRef = enemyInputRef;
        maxHeroes = 5;
        break;
      case 'pool':
        suggestions = poolSuggestions;
        inputValue = poolInputValue;
        placeholder = "Type hero name to add to your pool...";
        onHeroPick = (hero) => addHero(hero, 'pool');
        selectedHeroes = heroPool;
        disabledHeroes = [];
        inputRef = poolInputRef;
        maxHeroes = Infinity;
        break;
      case 'teamMate':
        suggestions = teamMateSuggestions;
        inputValue = teamMateInputValue;
        placeholder = "Type team mate hero name...";
        onHeroPick = (hero) => addHero(hero, 'teamMate');
        selectedHeroes = teamMateHeroes;
        disabledHeroes = enemyHeroes;
        inputRef = teamMateInputRef;
        maxHeroes = 5;
        break;
      default:
        return null;
    }

    return (
      <div className="dropdown-container">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => {
            switch (type) {
              case 'enemy':
                setEnemyInputValue(e.target.value);
                break;
              case 'pool':
                setPoolInputValue(e.target.value);
                break;
              case 'teamMate':
                setTeamMateInputValue(e.target.value);
                break;
            }
          }}
          onFocus={() => {
            setActiveDropdown(type);
            startPlaceholderAnimation(type);
          }}
          onBlur={() => stopPlaceholderAnimation(type)}
          placeholder={placeholderText[type]}
          className="input-field"
          disabled={selectedHeroes.length >= maxHeroes}
          ref={inputRef}
        />
        {activeDropdown === type && (
          <div className="dropdown-content">
            <div className="dropdown-close-button" onClick={() => setActiveDropdown(null)}>
              Close
            </div>
            {renderSuggestions(suggestions, type)}
            <HeroPickView 
              onHeroPick={onHeroPick}
              selectedHeroes={selectedHeroes}
              disabledHeroes={disabledHeroes}
            />
          </div>
        )}
      </div>
    );
  };

  const startPlaceholderAnimation = (type) => {
    const heroExamples = allHeroes.filter(hero => hero.name.length == 5);
    const selectedHero1 = heroExamples[Math.floor(Math.random() * heroExamples.length)];
    const heroName1 = selectedHero1.name;
    const selectedHero2 = heroExamples[Math.floor(Math.random() * heroExamples.length)];
    const heroName2 = selectedHero2.name;
    const selectedHero3 = heroExamples[Math.floor(Math.random() * heroExamples.length)];
    const heroName3 = selectedHero3.name;

    let step = 0;
    const animationSteps = [
      heroName1.slice(0, 1),
      heroName1.slice(0, 2),
      heroName1.slice(0, 3),
      heroName1.slice(0, 4),
      heroName1,
      heroName1,
      heroName1,
      heroName2.slice(0, 1),
      heroName2.slice(0, 2),
      heroName2.slice(0, 3),
      heroName2.slice(0, 4),
      heroName2,
      heroName2,
      heroName2,
      heroName3.slice(0, 1),
      heroName3.slice(0, 2),
      heroName3.slice(0, 3),
      heroName3.slice(0, 4),
      heroName3,
      heroName3,
      heroName3
    ];

    placeholderIntervalRef.current = setInterval(() => {
      if (step < animationSteps.length) {
        setPlaceholderText(prev => ({
          ...prev,
          [type]: animationSteps[step]
        }));
        step++;
      } else {
        clearInterval(placeholderIntervalRef.current);
        setPlaceholderText(prev => ({
          ...prev,
          [type]: `Type hero name or select from the drop-down list...`
        }));
      }
    }, step < 3 ? 300 : 1200);
  };

  const stopPlaceholderAnimation = (type) => {
    if (placeholderIntervalRef.current) {
      clearInterval(placeholderIntervalRef.current);
      setPlaceholderText(prev => ({
        ...prev,
        [type]: `Type ${type === 'pool' ? 'hero name to add to your pool' : type + ' hero name'}...`
      }));
    }
  };

  const generateCounterPicks = async () => {
    setIsLoading(prev => ({ ...prev, counterPicks: true }));
    setTableEnemyHeroes([...enemyHeroes]);
    setTableHeroPool([...heroPool]);

    const enemyHeroesParam = enemyHeroes.join(',').toLowerCase().replace(/ /g, '');
    const poolParam = heroPool.join(',').toLowerCase().replace(/ /g, '');
    const url = `https://dota-pick-582893384673.europe-central2.run.app/counter?enemy_heroes=${enemyHeroesParam}&pool=${poolParam}`;

    if (enemyHeroesParam !== "" && poolParam !== "") {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const processedData = data.hero_counters
          .filter(hero => !enemyHeroes.includes(hero.hero_name) && !heroPool.includes(hero.hero_name))
          .sort((a, b) => b.advantage - a.advantage);
        setCounterPicks(processedData);
      } catch (error) {
        console.error('Error fetching counter picks:', error);
        setCounterPicks(null);
      }
    } else {
      setCounterPicks(null);
    }

    setShowCounterPicks(true);
    setShowDoubleDown(false);
    setIsLoading(prev => ({ ...prev, counterPicks: false }));
  };

  const checkDoubleDown = async () => {
    setIsLoading(prev => ({ ...prev, doubleDown: true }));
    setTableEnemyHeroes([...enemyHeroes]);

    const enemyHeroesParam = enemyHeroes.join(',').toLowerCase().replace(/ /g, '');
    const teamMateHeroesParam = teamMateHeroes.join(',').toLowerCase().replace(/ /g, '');
    const url = `https://dota-pick-582893384673.europe-central2.run.app/counter?enemy_heroes=${enemyHeroesParam}&pool=${teamMateHeroesParam}`;

    if (enemyHeroesParam !== "" && teamMateHeroesParam !== "") {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        const processedData = data.hero_counters
          .sort((a, b) => b.advantage - a.advantage);
        
        const totalAdvantage = processedData.reduce((sum, hero) => sum + hero.advantage, 0);
        
        setDoubleDownResults({ heroes: processedData, totalAdvantage });
      } catch (error) {
        console.error('Error fetching double down results:', error);
        setDoubleDownResults(null);
      }
    } else {
      setDoubleDownResults(null);
    }

    setShowDoubleDown(true);
    setShowCounterPicks(false);
    setIsLoading(prev => ({ ...prev, doubleDown: false }));
  };

  const getColorForWeight = (weight) => {
    const normalizedWeight = Math.max(-5, Math.min(5, weight)) / 5;
    const red = normalizedWeight > 0 ? 255 * (1 - normalizedWeight) : 255;
    const green = normalizedWeight > 0 ? 255 : 255 * (1 + normalizedWeight);
    return `rgb(${Math.round(red)}, ${Math.round(green)}, 0)`;
  };

  const renderCounterPicksTable = () => {
    if (!counterPicks) {
      return <p>Please fill in the hero pool and the enemy hero sections.</p>;
    }

    const filteredCounterPicks = counterPicks.filter(hero => 
      !tableEnemyHeroes.includes(hero.hero_name) && !tableHeroPool.includes(hero.hero_name) && hero.average_winrate !== 0
    );

    if (filteredCounterPicks.length === 0) {
      return <p>No valid counter picks available. All your pool heroes seem to be used by enemies!</p>;
    }

    return (
      <div className="counter-picks-table">
        <h2>Counter Picks</h2>
        {isLowIQMode ? <p>Best hero to pick is <span className="highlight">{filteredCounterPicks[0].hero_name.toUpperCase()}</span></p> : ""}
        
        <table>
          <thead>
            <tr>
              <th>Hero</th>
              {tableEnemyHeroes.map(hero => (
                <th key={hero}>
                  <img src={getHeroImagePath(hero)} alt={hero} className="hero-icon" />
                </th>
              ))}
              <th>Advantage</th>
              <th>Est. Winrate</th>
            </tr>
          </thead>
          <tbody>
            {filteredCounterPicks.map(hero => (
              <tr key={hero.hero_name}>
                <td>
                  <img src={getHeroImagePath(hero.hero_name)} alt={hero.hero_name} className="hero-image" />
                </td>
                {hero.counters && hero.counters.map(counter => (
                  <td key={counter.enemy_hero} style={{backgroundColor: getColorForWeight(counter.weight)}}>
                    <p style={{color: "black"}}>{counter.weight.toFixed(2)}%</p>
                  </td>
                ))}
                <td>{hero.advantage !== undefined ? hero.advantage.toFixed(2) + '%' : 'N/A'}</td>
                <td>{hero.average_winrate !== undefined ? hero.average_winrate.toFixed(2) + '%' : 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderDoubleDownTable = () => {
    if (!doubleDownResults || !doubleDownResults.heroes || doubleDownResults.heroes.length === 0) {
      return <p>Please fill in the team mate and the enemy hero sections.</p>;
    }
    let wr = 0.0
    for (let i = 0; i < doubleDownResults.heroes.length; i++) {
      wr += doubleDownResults.heroes[i].average_winrate - doubleDownResults.heroes[i].advantage
    }
    wr = wr / doubleDownResults.heroes.length + doubleDownResults.totalAdvantage

    return (
      <div className="double-down-table">
        <h2>Double Down? {doubleDownResults.totalAdvantage > 8 ? <span className="highlight-green">Go for it!</span> : <span className="highlight">Nope</span>}</h2>
        {isLowIQMode ? (doubleDownResults.totalAdvantage > 8 ? <p>Your team heroes have overall advantage of <span className="highlight-green">{doubleDownResults.totalAdvantage.toFixed(2)}%</span> over enemy heroes. You should double down!</p> : <p>Your team heroes have overall advantage of {doubleDownResults.totalAdvantage.toFixed(2)}% over enemy heroes. This is not enough for win prediction (<span className="highlight">less than +8%</span>).</p>) : ""}
        <table>
          <thead>
            <tr>
              <th>Hero</th>
              {tableEnemyHeroes.map(hero => (
                <th key={hero}>
                  <img src={getHeroImagePath(hero)} alt={hero} className="hero-icon" />
                </th>
              ))}
              <th>Advantage</th>
              <th>Est. Winrate</th>
            </tr>
          </thead>
          <tbody>
            {doubleDownResults.heroes.map(hero => (
              <tr key={hero.hero_name}>
                <td>
                  <img src={getHeroImagePath(hero.hero_name)} alt={hero.hero_name} className="hero-image" />
                </td>
                {hero.counters && hero.counters.map(counter => (
                  <td key={counter.enemy_hero} style={{backgroundColor: getColorForWeight(counter.weight)}}>
                    <p style={{color: "black"}}>{counter.weight.toFixed(2)}%</p>
                  </td>
                ))}
                <td>{hero.advantage !== undefined ? hero.advantage.toFixed(2) + '%' : 'N/A'}</td>
                <td>{hero.average_winrate !== undefined ? hero.average_winrate.toFixed(2) + '%' : 'N/A'}</td>
              </tr>
            ))}
            <tr>
              <td colSpan={tableEnemyHeroes.length + 1}><strong>Total Advantage</strong></td>
              <td>{doubleDownResults.totalAdvantage.toFixed(2) + '%'}</td>
              <td>{(doubleDownResults.heroes.length != 5) ? wr.toFixed(2) + '%' : (50.0 + doubleDownResults.totalAdvantage).toFixed(2) + '%'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        activeDropdown &&
        !event.target.closest('.dropdown-container') &&
        !event.target.closest('.input-field')
      ) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeDropdown]);

  if (!imagesLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container">
      
      <div className="hero-picker-container">
        <h1>Dota 2: Double Down?</h1>
        <p>The <span className="highlight">Dota 2 Draft & Double Down</span> app helps you counter enemy picks and predict the outcome of matches based on team compositions.</p>
        <p>Select <span className="highlight">Team Mate Heroes</span> and <span className="highlight">Enemy Heroes</span> to decide whether to double down. Add heroes to <span className="highlight">Your Hero Pool</span> for counter picking.</p>

        <div className="low-iq-mode" onClick={toggleLowIQMode}>
          <img src="/logo192.png" alt="Pudge" />
          <span>Low IQ Mode{isLowIQMode ? ': Enabled' : ''}</span>
        </div>

        <div className={`low-iq-content ${isLowIQMode ? 'open' : ''}`}>
          <div className="low-iq-scrollable">
            <details>
              <summary>How to Use?</summary>
              <p><b>During the pick phase:</b></p>
              <ul className="step">
                <li>Select heroes for your <span className="highlight">Hero Pool</span> (a smarter guy would prefill it).</li>
                <li>Add enemy heroes to the <span className="highlight">Enemy Heroes</span> list.</li>
                <li>Click <span className="highlight">Suggest Counter Picks!</span> to see the best counters from your pool.</li>
              </ul>
              <p><b>After the pick phase:</b></p>
              <ul className="step">
                <li>Add heroes to both the <span className="highlight">Team Heroes</span> and <span className="highlight">Enemy Heroes</span> lists.</li>
                <li>Click <span className="highlight">Predict Double Down</span> to see if your team is likely to win!</li>
              </ul>
            </details>

            <details>
              <summary>How It Works?</summary>
              <p>The results are based on public data from the ranked matches played in the <span className="highlight">last 7 days</span>, the data is updated daily.</p>
              <p><span className="highlight">Advantage</span> values are based on the matchups between two heroes regardless of their normal winrate, and <span className="highlight">Est. Winrate</span> takes into account the average hero winrate in the current meta. The <span className="highlight"><a href="https://en.wikipedia.org/wiki/Addition">addition operation</a></span> (see example with apples) is applied to determine the resulting advantage values. A more accurate win probabilty estimation will be added soon.</p>
              <p>The results are the most accurate for the players of average skill <span className="highlight">1000-4000 MMR</span> but can also be useful in the higher skill brackets.</p>
            </details>

            <details>
              <summary>How to Disable the Low IQ Mode?</summary>
              <p>Click on <span className="highlight">Low IQ Mode</span> again (on the top). I don't think it helps though.</p>
              <p>Important: <span className="highlight">Pudge</span> has been added to your hero pool in advance.</p>
            </details>

          </div>
        </div>

        <div className="hero-section">
          <h2>Your Hero Pool</h2>
          {renderHeroList(heroPool, 'pool')}
          {renderDropdown('pool')}
        </div>

        <div className="hero-section">
          <h2>Enemy Heroes (Max 5)</h2>
          {renderHeroList(enemyHeroes, 'enemy')}
          {renderDropdown('enemy')}
        </div>

        <div className="hero-section">
          <h2>Team Mate Heroes (Max 5)</h2>
          {renderHeroList(teamMateHeroes, 'teamMate')}
          {renderDropdown('teamMate')}
        </div>

        <div className="button-container">
        <button 
          className="generate-button" 
          onClick={generateCounterPicks}
          disabled={isLoading.counterPicks}
        >
          {isLoading.counterPicks ? (
            <div className="button-loader">
              <div className="loader-dot"></div>
              <div className="loader-dot"></div>
              <div className="loader-dot"></div>
            </div>
          ) : (
            "Suggest Counter Picks!"
          )}
        </button>
        <button 
          className="double-down-button" 
          onClick={checkDoubleDown}
          disabled={isLoading.doubleDown}
        >
          {isLoading.doubleDown ? (
            <div className="button-loader">
              <div className="loader-dot"></div>
              <div className="loader-dot"></div>
              <div className="loader-dot"></div>
            </div>
          ) : (
            "Predict Double Down!"
          )}
        </button>
      </div>

        {showCounterPicks && renderCounterPicksTable()}
        {showDoubleDown && renderDoubleDownTable()}
      </div>

    </div>
  );
};

export default HeroPicker;
